.logo-place {
    display: flex;
    justify-content: flex-end;
    margin: 30px -200px -15px 0px
}


.desktop__error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    user-select: none;

}

.addContact__container {
    padding: 30px 24px;
    box-sizing: border-box;
}


.addContact__wrapper {
    display: flex;
    flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h1,
h6 {
    margin-bottom: 0px !important;
}



.addVehicle__message {
    margin-top: 40px;
    text-align: center;
}

.addVehicle__message>p {
    font-weight: 400;
    font-size: 14px;
    color: #9FA3A9;
}


.input-text {
    background-color: #D9D9D9;
    color: #000;
    border-radius: 9px;
    width: 100%;
    height: 44px;
    padding: 0px 20px;
    font-weight: 600;
    font-size: 18px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}

.error {
    color: #FF1C0E !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Gothic A1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
}

.error-input {
    margin-top: 5px;
    color: #FF1C0E;
}

input::placeholder {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Gothic A1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 200% */

}

.primary {
    color: #117800;
}

.contain_error {
    display: flex;
    /* text-align: left; */
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 600;
    color: #FF1C0E;
}

.sorry {
    color: black;
    font-size: 25px;
    font-family: Gothic A1;
    font-weight: 600;
    line-height: 30px;
    word-wrap: break-word;
    width: 278px !important
}


.restricted {
    color: black;
    font-size: 18px;
    font-family: Gothic A1;
    font-weight: 600;
    line-height: 22px;
    word-wrap: break-word
}

.input-field {
    border-radius: 5px;
    border: 1px solid #000;
    background: #D9D9D9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    padding: 0px 0px 0px 15px;
    /* width: 66px; */
    height: 39px;
    flex-shrink: 0;
}

.qr-image-wrapper section div {
    box-shadow: #003C25 0px 0px 0px 5px inset !important;
}

.reconnect {
    border-radius: 9px;
    border: 1px solid #FF1C0E;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-width: 111px;
    height: 48px;
    flex-shrink: 0;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Gothic A1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.ermerText {
    color: var(--Font-Dark, #0F0F0F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Gothic A1;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    /* 160% */
}

.emerGreenText {
    color: #117800;
    margin-top: -20px;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Gothic A1;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    /* 150% */
}

.radioButton {
    color: #000;
    background-color: #F9F9F9;
    max-width: 230px;
    min-width: 200px;
    height: 38px;
    font-size: 16px;
    border: 1px solid;
    border-radius: 9px;
    text-align: left;
    padding: 0px 10px;
}


.otp__Input>input {
    border-radius: 5px;
    border: 1px solid #000;
    background: #D9D9D9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 59px !important;
    height: 55px !important;
    flex-shrink: 0;
}
