body {
  margin: 0 !important;
  height: 100% !important;
}


.image-container {
  margin-right: -2px !important;
}

.image-container img {
  width: 100%;
  height: 200px;
}



.form-container {
  margin-top: -120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "outfit", sans-serif;
  margin-bottom: 20px;
}

.form-wrapper {
  background-color: #fff;
  width: 85%;
  max-width: 400px;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  text-align: center;
}

.form-title {
  margin-bottom: 20px;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-wrapper1 {
  position: relative;
  /* Makes it the reference point for absolute positioning of the error message */
  margin-bottom: 20px;

  /* Adds space between fields */
}

.form-input,
.form-select {
  padding: 0px;
  margin: 8px 0;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #333;
  font-weight: 400 ;
  font-size: 18px;
  /* Default bottom border */
  width: 100%;
  outline: none;

}

.input-error {
  border-bottom: 2px solid red;
  /* Red bottom border for errors */
}

.error-message {
  position: absolute;
  bottom: -10px;
  /* Position the error message below the input */
  left: 0;
  color: red;
  font-size: 12px;
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: left;
  /* Align the error message to the left */
}


.form-button {
  margin-top: 20px;
  font-size: 16px;
  color: white;
  background-color: #056344;
  border-radius: 30px;
  border: none;
  width: 50%;
  align-self: center;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;
  /* Add some padding to give space around the loader */
}

.form-button:hover {
  background-color: #0056b3;
}

/* Style for the disabled button */
.form-button.disabled,
.form-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}



/* Loader Style for Button */
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #056344;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Success message style */
.success-message {
  color: green;
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
}


.error-message-server {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
}



@media (max-width: 480px) {

  /* Make sure the form wrapper takes up 90% of the screen width on mobile */
  .form-wrapper {
    width: 90%;
    padding: 15px;
  }
  .form-button {
    width: 70%;
  }
}