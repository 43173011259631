.modal__style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 32px;
}


.conforming__cancleAndConfirm__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}