@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Gothic A1', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}


button {
  background-color: #003C25;
  max-width: 350px;
  min-width: 250px;
  height: 40px;
  color: #fff;
  margin-left: -2px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  margin-bottom: 10px;
}