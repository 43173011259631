@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::after,
*::before {
    margin: 0px;
    padding: 0px;
    -ms-overflow-style: none;
    /* IE  And Edge*/
    scroll-behavior: smooth;
    scrollbar-width: none;
    /* Firefox */
}

body {
    background-color: #fff !important;
    font-family: 'Gothic A1', sans-serif;
    background-color: #F5F5F5;
    margin: 0px 10px 0px 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.mainPage__Container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}


@import url("AddContact.css");
@import url("ModelToConformMobileNumber.css");
